<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >{{ $t('f_management.coin') }}</label
                >
                <validation-provider rules="required" :name="$t('f_management.coin')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="crypto.selectedCoin"
                      :options="currencies"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='name'
                      label='name'
                      :placeholder="$t('commons.choose')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label"
                  >Tx Id</label
                >
                <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    name="Tx Id"
                    type="text"
                    v-model="crypto.tx_id"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <form-submit-btn
            :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
            :isSubmitting="isSubmitting"
            @click="handleSubmit(createCrypto)"
            :text="$t('commons.submit')"
          />
        </div>
        <!--end::Actions-->
      </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { Skeleton } from 'vue-loading-skeleton';

export default {
  name: "New",
  data() {
    return {
      isSubmitting: false,
      selectedInstitutions: null,
      disabledInputUserId: ''
    };
  },
  components: {
    Multiselect,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cryptoTransaction.isLoading,
      currencies: (state) => state.cryptoTransaction.currencies,
      crypto: (state) => state.cryptoTransaction.crypto
    })
  },
  methods: {
    ...mapActions('cryptoTransaction', ["GET_CRYPTO_CREATE", "POST_CRYPTO_CREATE"]),
    createCrypto() {
      this.isSubmitting = true
      this.POST_CRYPTO_CREATE()
        .then(() => {
          this.$refs.form.reset();
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false
        })
    }
  },
  created() {
    this.GET_CRYPTO_CREATE();
  }
};
</script>
